<template>
  <el-card id="pending">
    <header>
      <h1>待处理续费查询</h1>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>公司ID</span>
            <el-input
              v-model="id"
              placeholder="请输入公司ID"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>公司名称</span>
            <el-input
              v-model="realname"
              placeholder="请输入公司名称"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>客服人员</span>
            <el-input
              v-model="operators"
              placeholder="请输入客服人员"
              size="mini"
            ></el-input>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="mini"
            class="searchBtn"
            >查找</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset"
            size="mini"
            class="reset"
            >重置</el-button
          >
          <el-button type="success" icon="el-icon-mic" @click="add" size="mini"
            >快速续费</el-button
          >
        </div>
      </div>
    </header>
    <hr />
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="id"
          label="公司ID"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="realname"
          label="公司名称"
          align="center"
        ></el-table-column
        ><el-table-column
          prop="username"
          label="登录账户"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="operators"
          label="客服"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="salesman"
          label="业务员"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="seat_rate"
          label="月租"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="seat_count"
          label="坐席额度"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="seat_phone_count"
          label="坐席数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="money"
          label="账户余额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="y_money"
          label="预续签金额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="s_money"
          label="次月扣费"
          align="center"
          class="hide"
        ></el-table-column>
        <el-table-column label="操作" width="100" align="center" fixed="right">
          <template #default="scope">
            <el-button type="primary" @click="update(scope)" size="mini"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes,jumper"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
    <!-- 弹出框 -->
    <el-dialog title="手动处理续费" :visible.sync="addFeesShow" width="30%">
      <el-form ref="form" :model="forms" label-width="100px">
        <el-form-item label="请选择公司">
          <el-select
            v-model="forms.parentId"
            filterable
            placeholder="请选择公司"
            :filter-method="getStates"
          >
            <el-option
              v-for="item in GSoptions"
              :key="item.id"
              :label="item.realname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="实续费金额"
          v-if="(parentId != 3555 || role != '客服') && getRenewBool()"
        >
          <el-input-number
            v-model="forms.r_money"
            :precision="3"
            :step="1"
            :min="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="续签结果">
          <el-select
            v-model="forms.renewResult"
            style="width: 50%"
            placeholder="续签结果"
            v-if="roleName == '管理员'"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            size="mini"
            v-model="forms.renewResult"
            style="width: 50%"
            placeholder="续签结果"
            v-if="roleName != '管理员'"
          >
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="forms.remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addFeesShow = false">取 消</el-button>
          <el-button type="primary" @click="addSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 编辑弹出框 -->
    <el-dialog title="处理续费" :visible.sync="updateShow" width="30%">
      <el-form ref="form" :model="forms" label-width="100px">
        <el-form-item
          label="实续费金额"
          v-if="(parentId != 3555 || role != '客服') && getRenewBool()"
        >
          <el-input-number
            size="mini"
            v-model="forms.r_money"
            :precision="3"
            :step="1"
            :min="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="续签结果">
          <el-select
            size="mini"
            v-model="forms.renewResult"
            style="width: 50%"
            placeholder="续签结果"
            v-if="roleName == '管理员'"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            size="mini"
            v-model="forms.renewResult"
            style="width: 50%"
            placeholder="续签结果"
            v-if="roleName != '管理员'"
          >
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="forms.remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="updateShow = false">取 消</el-button>
          <el-button type="primary" @click="updateSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  companyRenewAddWithRenew,
  companyRenewFindNoRenew,
} from "@/api/company_renew";
import { userFindlist } from "@/api/user";
const debounce = (function () {
  let timer = 0;
  return function (callback, ms = 200) {
    //设置默认200ms
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

export default {
  data() {
    return {
      role: null,
      forms: {},
      updateShow: false,
      addFeesShow: false,
      GSoptions: [],
      options: [
        {
          value: 0,
          label: "关停",
        },
        {
          value: 1,
          label: "已续费",
        },
        {
          value: 2,
          label: "待续费",
        },
        {
          value: 3,
          label: "商定中",
        },
        {
          value: 4,
          label: "不续费",
        },
        {
          value: 5,
          label: "转销售",
        },
        {
          value: 6,
          label: "新增坐席",
        },
        {
          value: 7,
          label: "减坐席",
        },
        {
          value: 8,
          label: "补开坐席",
        },
        {
          value: 9,
          label: "续费新增坐席",
        },
        {
          value: 10,
          label: "续费减坐席",
        },
      ],
      options1: [
        {
          value: 0,
          label: "关停",
        },
        {
          value: 2,
          label: "待续费",
        },
        {
          value: 3,
          label: "商定中",
        },
        {
          value: 4,
          label: "不续费",
        },
        {
          value: 5,
          label: "转销售",
        },
        {
          value: 7,
          label: "减坐席",
        },
        {
          value: 8,
          label: "补开坐席",
        },
      ],
      id: null,
      salesman: null,
      realname: null,
      username: null,
      operators: null,
      seat_rate: null,
      seat_count: null,
      seat_phone_count: null,
      money: null,
      y_money: null,
      s_money: null,
      r_money: null,
      create_time: null,
      change_time: null,
      remark: null,
      parentId: null,
      realname: null,
      operators: null,
      renewResult: null,
      roleName: null,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
      GSarr: [],
    };
  },
  created() {
    this.roleName = localStorage.getItem("roleName");
    this.parentId = localStorage.getItem("parentId");
    this.role = localStorage.getItem("role");
    if (this.roleName == "管理员") this.getGS();
    this.getList();
  },
  methods: {
    getRenewBool() {
      let arr = [1, 6, 9, 10];
      let bool = arr.includes(this.forms.renewResult);
      if (!bool) {
        this.forms.r_money = 0;
      }
      return bool;
    },
    getStates(val) {
      console.log("dadsad", val);
      debounce(async () => {
        let params = {
          page: 1,
          size: 100,
          parentId: 0,
          realname: val,
        };
        console.log("查询公司");

        let res = await userFindlist(params);
        if (res.data.statusCode == null) {
          this.GSoptions = res.data.data;
        } else {
          Message.error(res.data.message);
        }
      }, 1000);
    },
    async getGS(val) {
      let params = {
        page: 1,
        size: 500,
        parentId: 0,
        realname: null,
      };
      console.log("查询公司");
      let res = await userFindlist(params);
      if (res.data.statusCode == null) {
        this.GSoptions = res.data.data;
        this.GSarr = res.data.data;
      } else {
        Message.error(res.data.message);
      }
    },
    closeCrectSeat() {
      this.authority = "1";
      this.GSoptions = [...this.GSarr];
    },
    // 手动添加待续费
    add() {
      this.forms = {};
      this.addFeesShow = true;
      console.log("手动添加待续费");
    },
    // 编辑弹出框
    update(row) {
      this.updateShow = true;
      this.forms = row.row;
    },
    // 添加待续费
    addSubmit() {
      this.addFeesShow = false;
      let params = {
        parentId: this.forms.parentId,
        r_money: this.forms.r_money,
        renewResult: this.forms.renewResult,
        remark: this.forms.remark,
      };
      let subBool = this.getRenewBool()
      if (subBool){
        if(this.forms.r_money == 0){
          Message.warning("金额不能为0!");
          return false
        }else{
          params.r_money = this.forms.r_money
        }
      }
      if (this.forms.remark == null) {
        Message.warning("请填写备注！");
      } else if (!this.forms.parentId) {
        Message.warning("请选择公司!");
      } else {
        Message.success("处理成功!");
        let res = companyRenewAddWithRenew(params);
        res.then((res) => {
          Message.success(res.data.message);
          this.getList();
        });
      }
    },
    // 编辑确定
    updateSubmit() {
      let params = {
        parentId: this.forms.id,
        r_money: this.forms.r_money,
        renewResult: this.forms.renewResult,
        remark: this.forms.remark,
      };
      let subBool = this.getRenewBool()
      if (subBool){
        if(this.forms.r_money == 0){
          Message.warning("金额不能为0!");
          return false
        }else{
          params.r_money = this.forms.r_money
        }
      }
      if (this.forms.remark == null) {
        Message.warning("请填写备注！");
      } else {
        let res = companyRenewAddWithRenew(params);
        res.then((res) => {
          Message.success(res.data.message);
          this.updateShow = false;
          this.getList();
        });
      }
    },
    // 查询
    handleSearch() {
      this.page = 1;
      this.getList();
    },
    // 重置
    reset() {
      this.id = null;
      this.realname = null;
      this.operators = null;
      this.renewResult = null;
      this.getList();
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 展示
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        no_or_with_renew: null,
        operators: this.operators,
        parentId: this.id,
        realname: this.realname,
        renewResult: this.renewResult,
      };
      let res = companyRenewFindNoRenew(params);
      res.then((res) => {
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
#pending {
  header {
    h1 {
      font-weight: normal;
    }

    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;

        .left-wrap {
          display: flex;
          align-items: center;

          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }

  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
}
</style>
